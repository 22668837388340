import "./Badge.css";

function Badge({ text }) {
    return (
        <div className="badge">
            <p>{text}</p>
        </div>
    );
}

export default Badge;