import styles from "./PageNotFound.module.css";

function PageNotFound({ children }) {
  if (children) {
    return <PageNotFoundContainer>{children}</PageNotFoundContainer>;
  }
  return (
    <PageNotFoundContainer>
      <h1 style={{ fontSize: "2rem" }}>
        Sorry, we couldn't find this page 🤕{" "}
      </h1>
    </PageNotFoundContainer>
  );
}
function PageNotFoundContainer({ children }) {
  return <div className={styles.page}>{children}</div>;
}
export default PageNotFound;
