import { useEffect, useState } from "react";
import { useNavigate, NavLink } from "react-router-dom";
// components
import LanguageSelector from "../LanguageSelector/LanguageSelector";
import ApiUtil from "../../utils/ApiUtil";
// other
import cvPathEnglish from "../../assets/pdf/cv_en.pdf";
import cvPathSpanish from "../../assets/pdf/cv_es.pdf";
// styles
import "./NavBar.css";

export default function NavBar({
  profile,
  selectedLanguage,
  changeLanguageFn,
  showAdminOptions,
}) {
  const [responseMap, setResponseMap] = useState(new Map());
  const navigate = useNavigate();
  // language keys
  const navItemHome = "navbar-home-";
  const navItemJobs = "navbar-jobs-";
  const navItemPortfolio = "navbar-portfolio-";
  const navItemSkills = "navbar-skills-";
  const navItemContact = "navbar-contact-";
  const navItemCV = "navbar-cv-";
  const navItemLogin = "navbar-login-";
  const langChange = "navbar-lang-change-";
  const langLabel = "navbar-lang-";
  // other
  const cvPath = selectedLanguage === "es" ? cvPathSpanish : cvPathEnglish;
  useEffect(() => {
    getPageData();
  }, []);
  async function getPageData() {
    const apiResponse = await fetch(
      ApiUtil.getHost(profile) + ApiUtil.api.languages.translationNavbar,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
        },
      }
    ).then(async (response) => {
      return await response.json();
    });
    setResponseMap(new Map(Object.entries(apiResponse)));
  }
  const getLabelFn = (langs) => {
    const result = [];
    for (let i = 0; i < langs.length; i++) {
      result.push(
        responseMap.get(langLabel + langs[i] + "-" + selectedLanguage)
      );
    }
    return result;
  };
  const logout = () => {
    localStorage.clear();
    navigate(-1);
  };
  return (
    <nav className="site-nav grid">
      <h4>
        <a href="/#">
          <span>Andrés Della Porta</span>
        </a>
      </h4>
      {responseMap ? (
        <ul>
          {showAdminOptions ? (
            <>
              <li>
                <a href="/admin#" onClick={() => logout()}>
                  logout
                </a>
              </li>
            </>
          ) : (
            <>
              <li>
                <a href="/#">
                  {responseMap.get(navItemHome + selectedLanguage)}
                </a>
              </li>
              <li>
                <a href="/#jobs">
                  {responseMap.get(navItemJobs + selectedLanguage)}
                </a>
              </li>
              {/* <li><a href="/#portfolio">{responseMap.get(navItemPortfolio + selectedLanguage)}</a></li> */}
              <li>
                <a href="/#skills">
                  {responseMap.get(navItemSkills + selectedLanguage)}
                </a>
              </li>
              <li>
                <a href="/#contact">
                  {responseMap.get(navItemContact + selectedLanguage)}
                </a>
              </li>
              <li>
                <a
                  href={cvPath}
                  download={`${responseMap.get(
                    navItemCV + selectedLanguage
                  )}-andres-dellaporta-${selectedLanguage}.pdf`}
                >
                  {responseMap.get(navItemCV + selectedLanguage)}
                </a>
              </li>
              <li>
                <NavLink to="login" style={{ fontSize: "0.5em" }}>
                  🔑
                </NavLink>
              </li>
              {/* <li><a href="#login" onClick={() => toggleLoginFn()}>{responseMap.get(navItemLogin + selectedLanguage)}</a></li> */}
            </>
          )}
        </ul>
      ) : (
        <></>
      )}
      <LanguageSelector
        profile={profile}
        selectedLanguage={selectedLanguage}
        changeLanguageFn={changeLanguageFn}
        title={responseMap.get(langChange + selectedLanguage)}
        getLabelFn={getLabelFn}
      />
    </nav>
  );
}
