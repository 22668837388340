import imgSocialFacebook from "../assets/images/icons/icon_fb.svg";
import imgSocialTwitter from '../assets/images/icons/icon_tw.svg';
import imgSocialLinkedin from '../assets/images/icons/icon_linkedin.svg';
import imgSocialInstagram from '../assets/images/icons/icon_instagram.svg';
import imgSocialGithub from '../assets/images/icons/icon_github.svg';

const SocialMediaUtil = {
    
    linkedin: { src_: imgSocialLinkedin, alt_: "LinkedIn logo", href_: "https://www.linkedin.com/in/andresdellaporta" },
    github: { src_: imgSocialGithub, alt_: "Github logo", href_: "https://github.com/andresdellaporta" },
    instagram: { src_: imgSocialInstagram, alt_: "Instagram logo", href_: "https://www.instagram.com/_andresdp/" },
    facebook: { src_: imgSocialFacebook, alt_: "Facebook logo", href_: "https://facebook.com/" },
    twitter: { src_: imgSocialTwitter, alt_: "Twitter/X logo", href_: "https://x.com/" }

}

export default SocialMediaUtil;