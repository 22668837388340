import { useEffect, useState } from "react";
import ApiUtil from "../../utils/ApiUtil";

function LanguageSelector({ profile, selectedLanguage, changeLanguageFn, title, label, getLabelFn }) {
    const [languages, setLanguages] = useState([]);
    const languagesLabels = getLabelFn(languages);
    useEffect(() => {
        getAvailableLanguages();
    }, []);
    async function getAvailableLanguages() {
        try {
            const apiResponse = await fetch(
                ApiUtil.getHost(profile) + ApiUtil.api.languages.root,
                {
                    method: "GET",
                    headers: {
                        Accept: "application/json"
                    }
                }
            ).then(response => {
                return response.json();
            });
            setLanguages(await apiResponse);
        } catch (err) {
            console.log("err", err);
        }
    }
    return (
        <select
            name="lang" className="language-selector"
            value={selectedLanguage}
            onChange={(e) => changeLanguageFn(e.target.value)}
            title={title}
        >
            {languages.map((language, index) =>
                <option
                    key={"lang-" + language}
                    value={language}
                >
                    {languagesLabels[index]}
                </option>
            )}
        </select>
    );
}
export default LanguageSelector;