import { useState } from "react";
import { useNavigate } from "react-router-dom";
// components
import ApiUtil from "../../utils/ApiUtil";
// styles
import "./LoginPage.css";
// import styles from "./LoginPage.module.css";

function LoginPage({ profile }) {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  async function login(username, password) {
    return await fetch(ApiUtil.getHost(profile) + ApiUtil.api.auth.login, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ username: username, password: password }),
    });
  }
  async function submit(event) {
    event.preventDefault();
    try {
      const apiResponse = await login(username, password);
      if (apiResponse.status === 200) {
        const authToken = await apiResponse.text();
        localStorage.setItem("authToken", authToken);
        navigate("../admin");
        // window.location = "/admin";
        setUsername("");
        setPassword("");
      } else {
        const errorResponse = await apiResponse.json();
        alert(`${errorResponse.detail}`);
      }
    } catch (err) {
      console.log("err", err);
    }
  }
  function goBack(event) {
    event.preventDefault();
    navigate(-1);
  }
  return (
    <section id="login" className="bg-img-container">
      <div className="bg-img-gradient"></div>
      <div className="login-container">
        <h3>Login</h3>
        <form onSubmit={submit}>
          <input
            type="text"
            name="username"
            value={username}
            onChange={(event) => setUsername(event.target.value)}
            placeholder="USERNAME"
          />
          <input
            type="password"
            name="password"
            value={password}
            onChange={(event) => setPassword(event.target.value)}
            placeholder="PASSWORD"
          />
          <div className="btnContainer">
            <button className="button" type="submit">
              Send
            </button>
            <button className="button" onClick={(e) => goBack(e)}>
              Go back
            </button>
          </div>
        </form>
      </div>
    </section>
  );
}

export default LoginPage;
