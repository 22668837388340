import { useEffect, useState } from "react";
import WelcomeSection from "../../components/Section/WelcomeSection/WelcomeSection";
import JobsSection from "../../components/Section/JobsSection/JobsSection";
import SkillsSection from "../../components/Section/SkillsSection/SkillsSection";
import ContactSection from "../../components/Section/ContactSection/ContactSection";
// import PortfolioPage from '../../components/Section/PortfolioSection/PortfolioSection';
import FooterSection from "../../components/Footer/Footer";
import ApiUtil from "../../utils/ApiUtil";
import NavBar from "../../components/NavBar/NavBar";

function UserPage({ profile, selectedLanguage, changeLanguage }) {
  const [userPageTranslationMap, setUserPageDataMap] = useState(new Map());
  useEffect(() => {
    fetchUserPageTranslations();
  }, []);
  async function fetchUserPageTranslations() {
    const apiResponse = await fetch(
      ApiUtil.getHost(profile) + ApiUtil.api.languages.translationUserPage,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
        },
      }
    )
      .then(async (response) => await response.json())
      .catch([]);
    setUserPageDataMap(new Map(Object.entries(apiResponse)));
  }
  return (
    <>
      <NavBar
        profile={profile}
        selectedLanguage={selectedLanguage}
        changeLanguageFn={changeLanguage}
        showAdminOptions={false}
      />
      <WelcomeSection
        selectedLanguage={selectedLanguage}
        userPageTranslationMap={userPageTranslationMap}
      />
      <JobsSection
        selectedLanguage={selectedLanguage}
        userPageTranslationMap={userPageTranslationMap}
      />
      {/* <PortfolioPage selectedLanguage={selectedLanguage} userPageTranslationMap={userPageTranslationMap} /> */}
      <SkillsSection
        selectedLanguage={selectedLanguage}
        userPageTranslationMap={userPageTranslationMap}
      />
      <ContactSection
        profile={profile}
        selectedLanguage={selectedLanguage}
        userPageTranslationMap={userPageTranslationMap}
      />
      <FooterSection />
    </>
  );
}

export default UserPage;
