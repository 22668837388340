import { useState } from "react";
import ApiUtil from "../../utils/ApiUtil";

export default function ContactForm({ profile, selectedLanguage, userPageTranslationMap }) {
    // translationsMap keys
    const contactPageFormName = `user-page-contact-section-formName-${selectedLanguage}`;
    const contactPageFormEmail = `user-page-contact-section-formEmail-${selectedLanguage}`;
    const contactPageFormMsg = `user-page-contact-section-formMsg-${selectedLanguage}`;
    const contactPageFormBtn = `user-page-contact-section-formBtn-${selectedLanguage}`;
    const contactPageFormSubmitted = `user-page-contact-section-formSubmitted-${selectedLanguage}`;
    // form fields
    const [emitterName, setEmitterName] = useState('');
    const [fromEmail, setFromEmail] = useState('');
    const [body, setBody] = useState('');
    const message = {
        "emitterName": emitterName,
        "fromEmail": fromEmail,
        "body": body,
        "subject": `Message from ${emitterName} (${fromEmail})`,
        "preferredLang": selectedLanguage
    };
    async function handleSubmit(event) {
        event.preventDefault();
        try {
            await fetch(
                `${ApiUtil.getHost(profile)}${ApiUtil.api.messages.root}`
                + (selectedLanguage ? `?lang=${selectedLanguage}` : ""),
                {
                    "method": "POST",
                    "headers":
                    {
                        "Content-Type": "application/json",
                        "Accept": "text/plain;charset=UTF-8;application/json",
                        "Authorization": "Bearer " + localStorage.getItem("authToken")
                    },
                    "body": JSON.stringify(message)
                }
            ).then(async response => {
                if (response.status === 201) {
                    setEmitterName("");
                    setFromEmail("");
                    setBody("");
                    alert(userPageTranslationMap.get(contactPageFormSubmitted));
                } else {
                    const jsonResponse = await response.json();
                    console.log(jsonResponse.detail)
                    alert(jsonResponse.detail);
                }

            });
        } catch (exception) {
            console.error(exception.detail);
        }
    }
    return (
        userPageTranslationMap ?
            <form name="messageToDev" onSubmit={handleSubmit}>
                <input name="emitterName" type="text" placeholder={userPageTranslationMap.get(contactPageFormName)} value={emitterName} onChange={(e) => setEmitterName(e.target.value)} />
                <input name="fromEmail" type="email" placeholder={userPageTranslationMap.get(contactPageFormEmail)} value={fromEmail} onChange={(e) => setFromEmail(e.target.value)} />
                <textarea name="body" placeholder={userPageTranslationMap.get(contactPageFormMsg)} value={body} onChange={(e) => setBody(e.target.value)}></textarea>
                <button className="button" type="submit">{userPageTranslationMap.get(contactPageFormBtn)}</button>
            </form>
            :
            <>
            </>
    );
}