import "./ButtonGroup.css";

function ButtonGroup({ buttons: buttonsMap, className_, maxWidth_ }) {

    return (
        buttonsMap ?
            <>
                <ul className={className_ + " btn-grp"}>
                    {
                        Array.from(buttonsMap).map(([k, v]) => {
                            return (
                                <li key={`key-social-${k}`} style={{ maxWidth: maxWidth_ }}>
                                    <a href={v.href_} target="_blank"><img src={v.src_} alt={v.alt_} title={k} /></a>
                                </li>);
                        })
                    }
                </ul>

            </>
            :
            <>
            </>
    );
}

export default ButtonGroup;