import { useState } from "react";

export default function MessageRow({ message, markAsReadFn, deleteFn }) {
  const [wasRead, setWasRead] = useState(
    message.wasRead == (null | undefined) ? false : message.wasRead
  );
  function toggleWasRead() {
    if (window.confirm(`${wasRead ? "Unread" : "Read"} this message?`)) {
      setWasRead(markAsReadFn(message));
    }
  }
  return (
    <tr>
      <td>{message.id}</td>
      <td>{message.emitterName}</td>
      <td>{message.fromEmail}</td>
      <td>{message.subject}</td>
      <td>{message.creationTimeStamp}</td>
      <td>{message.preferredLang}</td>
      <td className="readBtn btn" onClick={() => toggleWasRead()}>
        {message.wasRead ? <>✅</> : <>☑️</>}
      </td>
      <td>{message.body}</td>
      <td onClick={() => deleteFn(message)} className="deleteBtn btn">
        ❌
      </td>
    </tr>
  );
}
