import './JobsSection.css';
import imgHexactaIcon from '../../../assets/images/icons/hexacta.png';
import imgGlobantIcon from '../../../assets/images/icons/icon_globant.svg';
import imgAtosIcon from '../../../assets/images/icons/atos.svg';
import imgEyIcon from '../../../assets/images/icons/icon_ey.svg';
import imgAmexIcon from '../../../assets/images/icons/amex.svg';
import { CompaniesUtil } from '../../../utils/CompaniesUtil';

function JobsSection({ selectedLanguage, userPageTranslationMap }) {
    // keys
    const jobsPageHeader = `user-page-jobs-section-header-${selectedLanguage}`;
    const job1 = `user-page-jobs-section-jobs-1-${selectedLanguage}`;
    const job2 = `user-page-jobs-section-jobs-2-${selectedLanguage}`;
    const job3 = `user-page-jobs-section-jobs-3-${selectedLanguage}`;
    const job4 = `user-page-jobs-section-jobs-4-${selectedLanguage}`;
    return (
        <section id="jobs" className="bg-img-container">
            <div className="bg-img-gradient-rotated"></div>
            {userPageTranslationMap ?
                <>
                    <h2>{userPageTranslationMap.get(jobsPageHeader)}</h2>
                    <div className="job-experience grid">
                        <a href={CompaniesUtil.hexacta.linkedinPage} target="_blank">
                            <img src={imgHexactaIcon} alt="Hexacta logo" />
                            <div className="job-experience-detail grid">
                                <h3>Hexacta</h3>
                                <h4>{userPageTranslationMap.get(job1)}</h4>
                                <p>2023-2024</p>
                            </div>
                        </a>
                        <a href={CompaniesUtil.globant.website} target="_blank">
                            <img src={imgGlobantIcon} alt="Globant logo" />
                            <div className="job-experience-detail grid">
                                <h3>Globant</h3>
                                <h4>{userPageTranslationMap.get(job1)}</h4>
                                <p>2022-2023</p>
                            </div>
                        </a>
                        <a href={CompaniesUtil.hexacta.linkedinPage} target="_blank">
                            <img src={imgHexactaIcon} alt="Hexacta logo" />
                            <div className="job-experience-detail grid">
                                <h3>Hexacta</h3>
                                <h4>{userPageTranslationMap.get(job1)}</h4>
                                <p>2021-2022</p>
                            </div>
                        </a>
                        <a href={CompaniesUtil.atos.website} target="_blank">
                            <img src={imgAtosIcon} alt="Atos logo" />
                            <div className="job-experience-detail grid">
                                <h3>Atos</h3>
                                <h4>{userPageTranslationMap.get(job2)}</h4>
                                <p>2020-2021</p>
                            </div>
                        </a>
                        <a href={CompaniesUtil.ey.website} target="_blank">
                            <img src={imgEyIcon} alt="EY logo" />
                            <div className="job-experience-detail grid">
                                <h3>Ernst & Young</h3>
                                <h4>{userPageTranslationMap.get(job3)}</h4>
                                <p>2018-2019</p>
                            </div>
                        </a>
                        <a href={CompaniesUtil.amex.website} target="_blank">
                            <img src={imgAmexIcon} alt="Amex logo" />
                            <div className="job-experience-detail grid">
                                <h3>American Express</h3>
                                <h4>{userPageTranslationMap.get(job4)}</h4>
                                <p>2013-2017</p>
                            </div>
                        </a>
                    </div>
                </>
                :
                <></>
            }
        </section>
    );
}

export default JobsSection;