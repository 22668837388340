import './SkillsSection.css';
import imgJava from '../../../assets/images/icons/icon_java.svg';
import imgSpringBoot from '../../../assets/images/icons/icon_springboot.svg';
import imgJavaScript from '../../../assets/images/icons/icon_javascript.svg';
import imgReact from '../../../assets/images/icons/icon_react.svg';
import imgMaven from '../../../assets/images/icons/icon_maven.svg';
import imgGit from '../../../assets/images/icons/icon_git.svg';
import imgMySQL from "../../../assets/images/icons/icon_mysql.svg";
import imgHibernate from '../../../assets/images/icons/icon_hibernate.svg';
import Badge from "../../Badge/Badge";

function SkillsSection({ selectedLanguage, userPageTranslationMap }) {
    // keys
    const skillsPageHeader  = `user-page-skills-section-header-${selectedLanguage}`;
    const langEs            = `user-page-skills-section-subsection-es-${selectedLanguage}`;
    const langEsLevel       = `user-page-skills-section-subsection-es-level-${selectedLanguage}`;
    const langEn            = `user-page-skills-section-subsection-en-${selectedLanguage}`;
    const langEnLevel       = `user-page-skills-section-subsection-en-level-${selectedLanguage}`;
    const langDe            = `user-page-skills-section-subsection-de-${selectedLanguage}`;
    const langDeLevel       = `user-page-skills-section-subsection-de-level-${selectedLanguage}`;
    const subHeaderCommunication =`user-page-skills-section-subsection-header-${selectedLanguage}`;
    return (
        <section id="skills">
            {
                userPageTranslationMap ?
                    <>
                        <h2>{userPageTranslationMap.get(skillsPageHeader)}</h2>
                        <ul className="grid hard-skills">
                            <li>
                                <img src={imgJava} alt="programming language Java" />
                                <h3>Java</h3>
                            </li>
                            <li>
                                <img src={imgSpringBoot} alt="framework Spring" />
                                <h3>Spring</h3>
                            </li>
                            <li>
                                <img src={imgJavaScript} alt="programming language JavaScript" />
                                <h3>JavaScript</h3>
                                <Badge text={"new"} />
                            </li>
                            <li >
                                <img src={imgReact} alt="library React" />
                                <h3>React</h3>
                                <Badge text={"new"} />
                            </li>
                            <li>
                                <img src={imgMaven} alt="build tool Maven" />
                                <h3>Maven</h3>
                            </li>
                            <li>
                                <img src={imgMySQL} alt="database MySQL" />
                                <h3>MySQL</h3>
                            </li>
                            <li>
                                <img src={imgHibernate} alt="version control Git" />
                                <h3>Hibernate</h3>
                            </li>
                            <li>
                                <img src={imgGit} alt="version control Git" />
                                <h3>Git</h3>
                            </li>
                        </ul>
                    </>
                    :
                    <></>
            }
            {/* languages */}
            {
                userPageTranslationMap ?
                    <>
                        <h3>{userPageTranslationMap.get(subHeaderCommunication)}</h3>
                        <ul className="communication-skills">
                            <li>
                                <h3>{userPageTranslationMap.get(langEs)}</h3>
                                <p>{userPageTranslationMap.get(langEsLevel)}</p>
                            </li>
                            <li>
                                <h3>{userPageTranslationMap.get(langEn)}</h3>
                                <p>{userPageTranslationMap.get(langEnLevel)}</p>
                            </li>
                            <li>
                                <h3>{userPageTranslationMap.get(langDe)}</h3>
                                <p>{userPageTranslationMap.get(langDeLevel)}</p>
                            </li>
                        </ul>

                    </>
                    :
                    <></>
            }
        </section>
    );
}

export default SkillsSection;