import { useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
// components
import LoginPage from "./pages/LoginPage/LoginPage";
import UserPage from "./pages/UserPage/UserPage";
import AdminPage from "./pages/AdminPage/AdminPage";
import PageNotFound from "./pages/PageNotFound/PageNotFound";
// other
import logo from "./logo.svg";
// styles
import "./App.css";

function App() {
  const profile = process.env.NODE_ENV;
  //console.log(`profile is  ${profile}`);
  const authToken = localStorage.getItem("authToken");
  const [selectedLanguage, setSelectedLanguage] = useState(
    localStorage.getItem("selectedLanguage") || "en"
  );
  const changeLanguage = (lang) => {
    localStorage.setItem("selectedLanguage", lang);
    setSelectedLanguage(lang);
  };
  const isValidToken = (authToken) => {
    return authToken ? true : false;
  };
  const [showAdminOptions, setShowAdminOptions] = useState(
    isValidToken(authToken)
  );

  return (
    <div className="App">
      <main>
        <BrowserRouter>
          <Routes>
            <Route
              path="/"
              element={
                <UserPage
                  profile={profile}
                  selectedLanguage={selectedLanguage}
                  changeLanguage={changeLanguage}
                ></UserPage>
              }
            ></Route>
            <Route
              path="/admin"
              element={
                <AdminPage
                  profile={profile}
                  selectedLanguage={selectedLanguage}
                  changeLanguage={changeLanguage}
                ></AdminPage>
              }
            ></Route>
            <Route path="login" element={<LoginPage profile={profile} />} />
            <Route path="*" element={<PageNotFound />} />
          </Routes>
        </BrowserRouter>
      </main>
    </div>
  );
}

export default App;
