import "./MessagesSection.css";
import { useEffect, useState } from "react";
import ApiUtil from "../../../utils/ApiUtil";
import MessageRow from "./MessageRow";

function MessagesSection({ profile }) {
  const [fetchCounter, setFetchCounter] = useState(0);
  const [messages, setMessages] = useState([]);
  useEffect(() => {
    fetchMessages(profile);
  }, []);
  async function fetchMessages(profile) {
    if (fetchCounter < 5) {
      const apiResponse = await fetch(
        `${ApiUtil.getHost(profile)}${ApiUtil.api.messages.root}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
        }
      )
        .then(async (response) => await response.json())
        .catch([]);
      setMessages([]);
      setMessages(apiResponse);
    } else {
      console.log("max fetch reached " + fetchCounter);
      setFetchCounter(fetchCounter + 1);
    }
  }
  function toggleWasRead(msg) {
    if (wasReadTogglePerformed(msg)) {
      const msgIndex = messages.findIndex((m) => m.id === msg.id);
      messages[msgIndex] = { ...messages[msgIndex], wasRead: !msg.wasRead };
      setMessages([...messages]);
      return !msg.wasRead;
    } else {
      // toggle not performed
      return msg.wasRead;
    }
  }
  function deleteMessage(msg) {
    if (
      window.confirm("Do you want to delete this message?") &&
      deletePerformed(msg)
    ) {
      const msgIndex = messages.findIndex((m) => m.id === msg.id);
      messages.splice(msgIndex, 1);
      setMessages([...messages]);
    }
  }
  async function wasReadTogglePerformed(msg) {
    return fetch(
      `${ApiUtil.getHost(profile)}${ApiUtil.api.messages.root}/${msg.id}`,
      {
        method: "PATCH",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
      }
    )
      .then((response) => (response.status == 200 ? true : false))
      .catch(false);
  }
  async function deletePerformed(msg) {
    return fetch(
      `${ApiUtil.getHost(profile)}${ApiUtil.api.messages.root}/${msg.id}`,
      {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
      }
    )
      .then((response) => (response.status == 200 ? true : false))
      .catch(false);
  }
  return (
    <div id="messages">
      <div>
        <ul className="msgTableOps">
          <li className="btn btnRefresh" onClick={() => fetchMessages(profile)}>
            <span>🔄 </span>
            <p>update messages </p>
          </li>
        </ul>
      </div>
      <table>
        <thead>
          <tr>
            <th>id</th>
            <th>name</th>
            <th>from</th>
            <th>subject</th>
            <th>timeStamp</th>
            <th>lang</th>
            <th>read</th>
            <th>body</th>
            <th>delete</th>
          </tr>
        </thead>
        <tbody>
          {messages ? (
            messages.map((message, index) => (
              <MessageRow
                key={`msg-key-${index}`}
                message={message}
                markAsReadFn={toggleWasRead}
                deleteFn={deleteMessage}
              />
            ))
          ) : (
            <></>
          )}
        </tbody>
      </table>
    </div>
  );
}

export default MessagesSection;
