import './WelcomeSection.css';
import cvPathEnglish from "../../../assets/pdf/cv_en.pdf";
import cvPathSpanish from "../../../assets/pdf/cv_es.pdf";
import PictureWithAttribution from '../../PictureWithAttribution/PictureWithAttribution';

function WelcomeSection({ selectedLanguage, userPageTranslationMap }) {
    const welcomePageHeader = "user-page-welcome-section-header";
    const welcomePageLeading = "user-page-welcome-section-leading";
    const welcomePagePortfolioBtn = "user-page-welcome-section-portfolioBtn";
    const welcomePagePortfolioCvBtn = "user-page-welcome-section-portfolioCvBtn";
    const welcomePagePortfolioCvFile = "user-page-welcome-section-portfolioCvFile";
    const cvPath = selectedLanguage === "es" ? cvPathSpanish : cvPathEnglish;
    const btnText = `${welcomePagePortfolioCvBtn}-${(selectedLanguage ? selectedLanguage : "en")}`;
    const fileName = `${welcomePagePortfolioCvFile}-${(selectedLanguage ? selectedLanguage : "en")}`;
    return (
        <section id="welcome" className="grid bg-img-container">
            <div className="bg-img-gradient"></div>
            <div className="welcome-text">
                {
                    userPageTranslationMap ?
                        <>
                            <h1>{userPageTranslationMap.get(welcomePageHeader + "-" + (selectedLanguage ? selectedLanguage : "en"))}</h1>
                            <p className="leading">{userPageTranslationMap.get(welcomePageLeading + "-" + (selectedLanguage ? selectedLanguage : "en"))}</p>
                            <a className="button btn2" href={cvPath} download={userPageTranslationMap.get(fileName)}>{userPageTranslationMap.get(btnText)}</a>
                            <a href="#contact" className="button btn1">{userPageTranslationMap.get(welcomePagePortfolioBtn + "-" + (selectedLanguage ? selectedLanguage : "en"))}</a>
                        </>
                        :
                        <></>
                }
            </div>
            <div className="welcome-img">
                <PictureWithAttribution />
            </div>
        </section>
    );
}

export default WelcomeSection;