import "./PictureWithAttribution.css";
import imgAstronaut from "../../assets/images/ilustrations/ilustration_astronaut.png";

function PictureWithAttribution() {
    return (
        <picture>
            <img src={imgAstronaut} alt="pic of planet" />
            <p className="pic-with-attribution">Designed by Freepik</p>
        </picture>
    );
}
export default PictureWithAttribution;