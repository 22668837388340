import './ContactSection.css';
import ContactForm from "../../ContactForm/ContactForm";

function ContactSection({ profile, selectedLanguage, userPageTranslationMap }) {
    // keys
    const contactPageHeader = `user-page-contact-section-header-${selectedLanguage}`;
    const contactPageSubtitle = `user-page-contact-section-subtitle-${selectedLanguage}`;
    return (
        <section id="contact" className="bg-img-container">
            {
                userPageTranslationMap ?
                    <>
                        <div className="bg-img-gradient"></div>
                        <h2>{userPageTranslationMap.get(contactPageHeader)}</h2>
                        <h4 className="leading">{userPageTranslationMap.get(contactPageSubtitle)}</h4>
                        <ContactForm profile={profile} selectedLanguage={selectedLanguage} userPageTranslationMap={userPageTranslationMap} />
                    </> :
                    <></>
            }
        </section>
    );
}

export default ContactSection;