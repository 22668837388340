const Info = {
  defaultProfile: "development",
  appContext: "/adp-be",
  apiRoot: "/adp-be/api",
};
const ApiUtil = {
  getHostsMap() {
    const hostsMap = new Map();
    hostsMap.set(Info.defaultProfile, "http://localhost:8080");
    hostsMap.set("production", "https://andresdellaporta.com");
    return hostsMap;
  },
  getHost(profile) {
    const hostsMap = this.getHostsMap();
    return profile && hostsMap.has(profile)
      ? hostsMap.get(profile)
      : hostsMap.get(Info.defaultProfile);
  },
  api: {
    auth: {
      root: Info.apiRoot + "/auth",
      login: Info.apiRoot + "/auth/login",
      logout: Info.apiRoot + "/auth/logout",
    },
    messages: {
      root: Info.apiRoot + "/messages",
      byId: Info.apiRoot + "/messages/{id}",
    },
    languages: {
      root: Info.apiRoot + "/languages",
      translationUserPage: Info.apiRoot + "/languages/userPageTranslations",
      translationAdminPage: Info.apiRoot + "/languages/adminPageTranslations",
      translationNavbar: Info.apiRoot + "/languages/navbarTranslations",
    },
  },
};

export default ApiUtil;
