export const CompaniesUtil = {
    notFound: { website: "", linkedinPage: ""},
    hexacta: { website: "", linkedinPage: "https://ar.linkedin.com/company/hexacta" },
    globant: { website: "https://www.globant.com" },
    atos: { website: "https://atos.net" },
    ey: { website: "https://www.ey.com" },
    amex: { website: "https://www.americanexpress.com" },
    getCompany(name) {
        let company = null;
        switch (name) {
            case "hexacta":
                company = this.hexacta;
            case "globant":
                company = this.globant;
            case "atos":
                company = this.atos;
            case "ey":
                company = this.ey;
            case "amex":
                company = this.amex;
            default:
                company = this.notFound;
        }
        return company;
    }
}
