import { Link } from "react-router-dom";
// styles
import styles from "./UnauthorizedPage.module.css";
function UnauthorizedPage({ message }) {
  return (
    <div className={styles.unauthorized}>
      <h3>Unauthorized access 🧐</h3>
      <h2>{message}</h2>
      <h3>
        <Link to="../login">Go to login</Link>
      </h3>
    </div>
  );
}

export default UnauthorizedPage;
