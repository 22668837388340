
import './Footer.css';
import ButtonGroup from '../ButtonGroup/ButtonGroup';
import SocialMediaUtil from '../../utils/SocialMediaUtil';
import imgMoon from '../../assets/images/ilustrations/ilustration_moon.png';

export default function Footer() {
    const btns = new Map([
            ["linkedin", SocialMediaUtil.linkedin],
            ["github", SocialMediaUtil.github],
            ["instagram", SocialMediaUtil.instagram],
    ]);
    return (
        <div id="footer">
            <img src={imgMoon} />
            <footer>
                <div className="grid">
                    <p className="copyright">Copyright 2024 © Andrés Della Porta</p>
                    <ButtonGroup className_="social" buttons={btns} />
                </div>
            </footer>
        </div>
    );
}