import MessagesSection from "../../components/Section/MessagesSection/MessagesSection";
import NavBar from "../../components/NavBar/NavBar";
import UnauthorizedPage from "../UnauthorizedPage/UnauthorizedPage";

function AdminPage({ profile, selectedLanguage, changeLanguage }) {
  if (localStorage.getItem("authToken")) {
    return (
      <>
        <NavBar
          profile={profile}
          selectedLanguage={selectedLanguage}
          changeLanguageFn={changeLanguage}
          showAdminOptions={true}
        />
        <MessagesSection profile={profile} />
      </>
    );
  }
  return <UnauthorizedPage message="Please log in to see this information" />;
}

export default AdminPage;
